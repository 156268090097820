import { SENTRY_DSN } from '@/config/constants'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import packageJson from '../../package.json'

Sentry.init({
  enabled: false, // disable sentry on ETC
  dsn: SENTRY_DSN,
  release: `safe-wallet-web@${packageJson.version}`,
  integrations: [new Integrations.BrowserTracing()],
  sampleRate: 0.1,
  // ignore MetaMask errors we don't control
  ignoreErrors: ['Internal JSON-RPC error', 'JsonRpcEngine', 'Non-Error promise rejection captured with keys: code'],

  beforeSend: (event) => {
    return null // disable sentry on ETC

    // Remove sensitive URL query params
    // const query = event.request?.query_string
    // if (event.request && query) {
    //   const appUrl = typeof query !== 'string' && !Array.isArray(query) ? query.appUrl : ''
    //   if (appUrl) {
    //     event.request.query_string = { appUrl }
    //   } else {
    //     delete event.request.query_string
    //   }
    // }
    // return event
  },
})

export default Sentry
